.main-container {
   position: relative;
}

.container-img {
    /* background-image: url("./assets/images/pokershots_bg.png");
    background-repeat: no-repeat;
    background-size: cover; */
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background-position: center;
}

.text-container {
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   width: 100%;
   top:6%;
}

.pokershots-text {
    font-size: 90px;
    /* line-height: 6vw; */
    font-weight: 700;
    font-family: poppins;
    background: -webkit-linear-gradient(180deg, #25FFA4 0%, #0086F7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* For screens smaller than 600px */
@media (max-width: 600px) {
    .pokershots-text {
      font-size: 58px;
    }
  }
  
  /* For screens between 600px and 960px */
  @media (min-width: 600px) and (max-width: 960px) {
    .pokershots-text {
      font-size: 60px;
    }
  }

  /* @media (min-width: 960px) {
    .pokershots-text {
      font-size: 90px;
    }
  } */
  
  

